import { Button, Flex, FormControl, FormErrorMessage, Input as ChakraInput, InputGroup, InputLeftElement, InputProps as ChakraInputProps, InputRightElement } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useState } from 'react'
import { FieldError } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


interface InputProps extends ChakraInputProps {
    id: string;
    placeholder: string;
    icon: ReactNode;
    error?: FieldError;
    mask?: string;
}

export const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ placeholder, icon, mt, error, mask, ...rest }, ref) => {

    const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (mask != undefined) {
            //@ts-ignore
            if (e.currentTarget.attributes.mask.value == 'cpf') {
                //Mask to cpf
                e.currentTarget.maxLength = 14;
                let value = e.currentTarget.value;
                value = value.replace(/\D/g, ""); // \D pegar tudo que não é número, g continuar a ler a string
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1-$2');
                e.currentTarget.value = value;
                //@ts-ignore
            } else if (e.currentTarget.attributes.mask.value == 'phone') {
                //Mask to phone
                e.currentTarget.maxLength = 15;
                let value = e.currentTarget.value;
                value = value.replace(/\D/g, "");
                value = value.replace(/(\d{2})(\d)/, "($1) $2");
                value = value.replace(/(\d{4})(\d)/, "$1-$2");
                value = value.replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3");


                e.currentTarget.value = value;
                //@ts-ignore
            } else if (e.currentTarget.attributes.mask.value == 'birthdate') {
                e.currentTarget.maxLength = 10;
                let value = e.currentTarget.value;
                value = value.replace(/\D/g, "");
                value = value.replace(/(\d{2})(\d)/, "$1/$2");
                value = value.replace(/(\d{2})(\d)/, "$1/$2");

                e.currentTarget.value = value;
            }
        }


    }, []);
    return (
        <FormControl isInvalid={!!error} >
            <InputGroup border={!error ? '1px solid #1F2B50' : ''} borderRadius='5px'>
                <InputLeftElement children={icon} />
                <ChakraInput onKeyUp={handleKeyUp} mask={mask} placeholder={placeholder} border='none' focusBorderColor='none' _placeholder={{ color: '#1f2b50' }} ref={ref} {...rest} />
            </InputGroup>

            {!!error && (
                <FormErrorMessage>
                    {error.message}
                </FormErrorMessage>
            )}
        </FormControl>
    )
}

export const Input = forwardRef(InputBase);

const InputPasswordBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ placeholder, mt, icon, error, ...rest }, ref) => {

    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    return (
        <FormControl isInvalid={!!error} mt={mt}>
            <InputGroup border={!error ? '1px solid #1F2B50' : ''} borderRadius='5px'>
                <InputLeftElement>
                    {icon}
                </InputLeftElement>
                <ChakraInput
                    pr='4.5rem'
                    type={show ? 'text' : 'password'}
                    placeholder={placeholder} _placeholder={{ color: '#1F2B51' }} border='none' focusBorderColor='none'
                    ref={ref}
                    {...rest}
                />
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                        {show ? <FaEyeSlash size={20} /> : <FaEye size={18} />}
                    </Button>
                </InputRightElement>
            </InputGroup>

            {!!error && (
                <FormErrorMessage>
                    {error.message}
                </FormErrorMessage>
            )}
        </FormControl>
    )
}

export const InputPassword = forwardRef(InputPasswordBase);

