import { Avatar, Flex, Icon, Text, useMediaQuery, Image, Box, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverBody, PopoverCloseButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Stack, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { FaUserTie } from "react-icons/fa";
import { Camera } from "../Camera";
import IconCamera from "../../assets/icon-camera.svg"
import { person } from "./data";
import { GiExitDoor } from 'react-icons/gi'
import { useAuth } from "../../contexts/authentication";
import api from "../../services/api";
import { MdCameraswitch } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../Button";

interface ILayout {
    pageType: string;
    children: React.ReactNode
}

const Layout = ({ pageType, children }: ILayout) => {

    const [isShorterThan760] = useMediaQuery('(max-height: 760px)');

    const gradient = 'linear-gradient(180deg, rgba(255,255,255,1) 12%, rgba(238,240,245,1) 67%, rgba(220,225,239,1) 100%)';

    const [photo, setPhoto] = useState('');
    const [hasPhoto, setHasPhoto] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isCaptured, setIsCaptured] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState('');
    const [isCouto, setIsCouto] = useState(false); 
    const { user, signOut, actualApp } = useAuth();


    const { register, handleSubmit } = useForm();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {  isOpen: isOpenHasPhoto, onOpen: onOpenHasPhoto, onClose: onCloseHasPhoto } = useDisclosure();
    const {  isOpen: isOpenAlreadyExists, onOpen: onOpenAlreadyExists, onClose: onCloseAlreadyExists } = useDisclosure();
    const message = useToast();
    let count = 0;


    const sendPhotoUpdate = async (data: string) => {
        const photo = data.split(',')[1];

        api.post(`/app/upload?uuid=${user.uuid}`, {
            user_uuid: user.uuid,
            photo: photo,
            origin: actualApp.includes('tonocouto') ? 'couto' : 'unievangelica'
        })
            .then(res => {
                console.log(res)
                setImage(data)
                setIsRecording(false)
                setHasPhoto(true)
                setIsCaptured(true)
                setIsLoading(false);
                count = 0;
                window.location.reload();
                message({
                    title: 'Acesso Liberado!',
                    description: 'Faça Login novamente!',
                    status: 'success',
                    duration: 7000,
                    isClosable: true,
                    position: 'top'
                });
                signOut();
                return true;
            }
            )
            .catch(err => {
                console.log(err)

                if((err.response.status == 400 || err.response.status == 500) && count == 0) {
                    count = 1;
                    sendPhoto(data);
                } else {
                    let textError;
                const error = err.response.data.errors[0];

                switch (error) {
                    case 'low-sharpness':
                        textError = 'Limpe a câmera e procure um fundo neutro.';
                        break;
                    case 'no-face':
                        textError = 'Nenhuma face reconhecida.';
                        break;
                    case 'many-faces':
                        textError = 'Mais de uma pessoa na foto.';
                        break;
                    case 'sunglasses':
                        textError = 'Retire o óculos de sol.';
                        break;
                    case 'eyes-close':
                        textError = 'Mantenha os olhos abertos.';
                        break;
                    case 'low-brightness':
                        textError = 'Foto muito escura.';
                        break;
                }
                message({
                    title: 'Erro ao enviar a foto!',
                    description: textError,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
                setHasPhoto(false);
                setIsRecording(true);
                setIsError(true);
                setIsLoading(false);
                return false;
                }

                
            })
    }
    const sendPhoto = async (data: string) => {
        const photo = data.split(',')[1];

        api.post(`/app/upload?uuid=${user.uuid}`, {
            user_uuid: user.uuid,
            photo: photo,
            origin: actualApp.includes('tonocouto') ? 'couto' : 'unievangelica'
        })
            .then(res => {
                console.log(res)
                setImage(data)
                setIsRecording(false)
                setHasPhoto(true)
                setIsCaptured(true)
                setIsLoading(false);
                count = 0;
                window.location.reload();
                return true;
            }
            )
            .catch(err => {
                console.log(err)

                if((err.response.status == 400 || err.response.status == 500) && count == 0) {
                    count = 1;
                    sendPhoto(data);
                } else {
                    let textError;
                const error = err.response.data.errors[0];

                switch (error) {
                    case 'low-sharpness':
                        textError = 'Limpe a câmera e procure um fundo neutro.';
                        break;
                    case 'no-face':
                        textError = 'Nenhuma face reconhecida.';
                        break;
                    case 'many-faces':
                        textError = 'Mais de uma pessoa na foto.';
                        break;
                    case 'sunglasses':
                        textError = 'Retire o óculos de sol.';
                        break;
                    case 'eyes-close':
                        textError = 'Mantenha os olhos abertos.';
                        break;
                    case 'low-brightness':
                        textError = 'Foto muito escura.';
                        break;
                }
                message({
                    title: 'Erro ao enviar a foto!',
                    description: textError,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
                setHasPhoto(false);
                setIsRecording(true);
                setIsError(true);
                setIsLoading(false);
                return false;
                }

                
            })
    }

    useEffect(() => {
        if(actualApp.includes('tonocouto')){
            setIsCouto(true)  
            api.get(`https://tonocouto.aee.edu.br/api/img-profiles/${user.uuid}.jpg`, {responseType:"blob", headers: {'Cache-Control': 'no-cache'}
            }).then(res => {
                const reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = () => {
                    const base64 = reader.result;
                    //@ts-ignore
                    setPhoto(base64);
                }
                
                res.status == 200 ? setHasPhoto(true) : setHasPhoto(false);
            }).catch(err => {
                    console.log(err);
                    if(err.response.status == 404) {
                        setHasPhoto(false);
                        // onOpenHasPhoto();
                    }
            })
            if(!user.allow_acess.includes('couto')) {
                onOpenAlreadyExists()
            }
        } else {
            setIsCouto(false)
            api.get(`https://tonauni.unievangelica.edu.br/api/img-profiles/${user.uuid}.jpg`, {responseType:"blob", headers: {'Cache-Control': 'no-cache'}
            }).then(res => {
                const reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = () => {
                    const base64 = reader.result;
                    //@ts-ignore
                    setPhoto(base64);
                }
                
                res.status == 200 ? setHasPhoto(true) : setHasPhoto(false);
            }).catch(err => {
                    console.log(err);
                    if(err.response.status == 404) {
                        setHasPhoto(false);
                        onOpenHasPhoto();
                    }
            })
            if(!user.allow_acess.includes(process.env.REACT_APP_ORIGIN)) {
                onOpenAlreadyExists()
            }
        }

        
    }, [])

    return (
        <>
            {!isRecording ? (
                <Flex alignItems='center' position='absolute' top={2} right={2} color='gray.50' onClick={() => signOut()}>
                    <>
                        <GiExitDoor size='2.3rem' />
                        <Text ml='0.2rem'>Sair</Text>
                    </>
                </Flex>
            ) : null}


            {isRecording ? (
                <Camera sendPhoto={sendPhoto} isError={isError} setIsError={setIsError} isLoading={isLoading} setIsLoading={setIsLoading} />
            ) : (
                <>
                    {hasPhoto && !isCaptured ? (
                        <>
                            <Flex flexDir='column' align='center' color='white' >
                                <Popover>
                                    <PopoverTrigger>
                                        <Avatar boxSize='11rem' mt='2rem' src={isCouto ? `https://tonocouto.aee.edu.br/api/img-profiles/${user.uuid}.jpg` : `https://tonauni.unievangelica.edu.br/api/img-profiles/${user.uuid}.jpg`} />
                                    </PopoverTrigger>
                                    <PopoverContent w='100%' bgColor='#1F2B50' border='none' >
                                        <Flex justify='center'>
                                            <Button
                                                label="Trocar foto"
                                                onClick={onOpen}
                                            />
                                        </Flex>
                                    </PopoverContent>
                                </Popover>
                            </Flex>
                        </>
                    ) : hasPhoto && isCaptured ? (
                        <Flex justify='center' align='center' w='100%' >
                            <Flex flexDir='column' w='11rem' h='11rem' borderRadius='8rem' color='#1F2B51' justify='center' align='center' mt='4rem' display={hasPhoto ? 'block' : 'none'} >
                                <Avatar boxSize='11rem' src={image} borderRadius='8rem' />
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex justify='center' align='center' w='100%' >
                            <Flex flexDir='column' w='11rem' h='11rem' bgColor='#FCFCFC' borderRadius='8rem' color='#1F2B51' justify='center' align='center' mt='1rem'>
                                <Icon as={FaUserTie} fontSize='7xl' />
                                <Image src={IconCamera} boxSize='1.7rem' mt='2rem' color='black' onClick={() => setIsRecording(true)} />
                            </Flex>
                        </Flex>
                    )}

                    <Flex justify='center'>
                        <Flex flexDir='column'  mt='1rem' w='92%' h={isShorterThan760 ? '100%' : 'calc(100vh - 13rem)'} borderTopRadius='1.7rem' maxW='450px' style={{background: 'linear-gradient(180deg, rgba(255,255,255,1) 12%, rgba(238,240,245,1) 67%, rgba(220,225,239,1) 100%)'}}>
                            <Flex flexDir='row' justify='center' align='center' mb='2rem' bgColor='#237FC3' w='100%' borderTopRadius='1.7rem' minH='3rem' color='#fff'>
                                <Text fontSize='lg'>{pageType}</Text>
                            </Flex>
                            {children}
                        </Flex>
                    </Flex>
                </>
            )}

            <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent bgColor='#FAFAFA' w='85%'>
                    <ModalHeader textAlign='center'>Instruções para foto</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Stack spacing={2}>
                            <Text>- Posicione o rosto em frente a câmera;</Text>
                            <Text>- Mantenha os olhos abertos;</Text>
                            <Text>- Escolha um fundo neutro;</Text>
                            <Text>- Certifique-se que seu rosto estará visível.</Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter justifyContent='center'>
                        <Button
                            mr={3}
                            label={"Ok"}
                            onClick={() => {
                                onClose();
                                setIsRecording(true);
                            }}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenHasPhoto} onClose={onCloseHasPhoto} closeOnEsc={false}  closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent bgColor='#FAFAFA' w='85%'>
                    <ModalHeader textAlign='center'>Atenção!</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Stack spacing={2}>
                            <Text>Para continuar é necessário adicionar a sua foto ao sistema</Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter justifyContent='center'>
                        <Button
                            mr={3}
                            label={"Adicionar foto"}
                            onClick={() => {
                                onCloseHasPhoto();
                                onOpen();
                            }}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenAlreadyExists} onClose={onCloseAlreadyExists} closeOnEsc={false}  closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent bgColor='#FAFAFA' w='85%'>
                    <ModalHeader textAlign='center'>Você já possui um cadastro!</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Stack spacing={2}>
                            <Text>Conseguimos identificar que você já possui um cadastro.</Text>
                            <Text>Para liberar seu acesso {isCouto ? 'ao Colégio Couto Magalhães' : 'a Unievangelica'} clique no botão "Liberar Acesso".</Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter justifyContent='center'>
                        <Button
                            mr={3}
                            label={"Liberar acesso"}
                            onClick={() => {
                                sendPhotoUpdate(photo);
                                onCloseAlreadyExists();
                            }}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Layout;