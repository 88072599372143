import React from "react";
import {
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps,
    Navigate
} from 'react-router-dom';
import { JWTDecodedProps, useAuth } from "../contexts/authentication";


function IsAuthenticated() {
    const { token } = useAuth();

    return token ? true : false;
}
// @ts-ignore
const PrivateRoute = ({ children, redirectTo }) => {

    if (window.location.href.split(':')[0] == 'http') {
        window.location.assign(`https://${window.location.href.split('http://')[1]}`)
    }

    return IsAuthenticated() ? children : <Navigate to={redirectTo} />
}

export default PrivateRoute;