export const person = {
    name: 'Alvaro Lopes Bastos',
    course: 'Engenharia de computação',
    type: 'Professor UniEVANGÉLICA',
    cpf: '44430320888',
    validity:'2022-12-31T19:58:47.1234567',
    photo: 'https://bit.ly/dan-abramov',
    phone: '62991495708',
}

