import React from 'react';

import { AuthProvider, AuthProviderProps } from './authentication';

const AppProvider = ({children}: AuthProviderProps) => (
  <AuthProvider>
    {children}
  </AuthProvider>
);

export default AppProvider;
