import { Box, Flex, HStack, Select, Stack, Text, useToast, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Avatar } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { FaMapMarked } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout"
import api from "../../services/api";
import QrReader from "react-web-qr-reader";
import { useAuth } from "../../contexts/authentication";

interface IStudent {
    uuid: string;
    name: string;
    registration: string;
    photo: string;
}

type ILocations = [
    {
        place: string;
        value: number;
    }
]


export const Roll = () => {

    const navigate = useNavigate();
    const { user } = useAuth()
    const message = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();


    const [isReading, setIsReading] = useState(false);
    const [student, setStudent] = useState<IStudent>();
    const [isLoading, setIsLoading] = useState(false);
    const [local, setLocal] = useState(Number);
    const [isFetching, setIsFetching] = useState(false);
    const [locations, setLocations] = useState<ILocations>([{ place: '', value: 0 }]);
    const [type, setType] = useState<Number>();

    let places: ILocations = [
        {
            place: 'Meu Local',
            value: 0
        }
    ];


    user.checkins.map(place => places.push({ place: place.name, value: place.id }));
    useEffect(() => {
        setLocations(places)
    }, [])

    const fetchStudent = async () => {
        setIsLoading(true);
        const body = {
            uuid: student?.uuid,
            place: local,
            type: type,
            date: Date.now()
        }

        console.log(body);

        // try {
        //     setIsFetching(true);
        //     await api.post('app/checkin', {
        //         uuid: student?.id,
        //         place: local,
        //         type: type,
        //         date: Date.now()
        //     })
        //         .then((res) => {
        //             setIsReading(false)
        //             setIsFetching(false)
        //             onClose()
        //         })
        // } catch (error) {
        //     message({
        //         title: 'Dados enviados!',
        //         description: 'Ok',
        //         status: 'success',
        //         duration: 5000,
        //         isClosable: true,
        //         position: 'top'
        //     })
        //     setIsReading(false);
        //     setIsFetching(false);
        //     onClose();
        // }

    }

    const delay = 500;

    const previewStyle = {
        height: 240,
        width: 320
    };


    const handleScan = (result: any) => {
        try {
            api.get(`app/checkin/${result.data}`)
                .then((res) => {
                    console.log(res)
                    setStudent(res.data);
                })
            setIsReading(false);
            onOpen();
        } catch (error) {
            console.log(error);
            message({
                title: 'Dados enviados!',
                description: 'Ok',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            setIsReading(false);
            throw new Error('Student not Found!')
        }
    };

    const handleError = (error: any) => {
        console.log(error);
    };

    return (
        <Layout pageType={"Frequência"}>
            <Box w='100%' height='100%' p={8} >
                <Flex border='1px solid #1F2B50' borderRadius='5px' justify='center' alignItems='center' p='0 0.5rem 0 0.5rem'>
                    <FaMapMarked size='1.3rem' />
                    <Select border='none' focusBorderColor="none" onChange={(e) => setLocal(parseInt(e.target.value))} >
                        {/* <option key={1} value="">Meu Local</option> */}
                        {
                            locations.map((local, index) => {
                                return (

                                    <option key={index} value={local.value}>{local.place}</option>
                                )
                            })
                        }
                    </Select>
                </Flex>
                <Flex w='100%' mt='4rem' flexDir='column' alignItems='center'>
                    <Text>Ler QRcode para realizar a frequência</Text>

                    <HStack spacing={8} mt='1rem' w='100%'>
                        <Button
                            label="Entrada"
                            w='100%'
                            onClick={() => {
                                if (local != 0) {
                                    setType(0)
                                    setIsReading(true)
                                } else {
                                    message({
                                        title: 'Erro ao identificar seu local!',
                                        description: 'Selecione o seu local antes de marcar a frequência!',
                                        status: 'warning',
                                        duration: 5000,
                                        isClosable: true,
                                        position: 'top'
                                    })
                                }
                            }}

                        />
                        <Button
                            label="Saída"
                            w='100%'
                            onClick={() => {
                                if (local != 0) {
                                    setType(1)
                                    setIsReading(true)
                                } else {
                                    message({
                                        title: 'Erro ao identificar seu local!',
                                        description: 'Selecione o seu local antes de marcar a frequência!',
                                        status: 'warning',
                                        duration: 5000,
                                        isClosable: true,
                                        position: 'top'
                                    })
                                }
                            }}
                        />
                    </HStack>

                    <Button label="Histórico" mt='9rem' onClick={() => navigate('/history')} />
                </Flex>

                {isReading ? (
                    <Box bgColor='blackAlpha.800' w='100%' h='100%' position='absolute' top={0} left={0} pt='70%'>
                        {
                            isFetching ? (
                                <Flex justify='center'>
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='xl'
                                    />
                                </Flex>
                            ) : (
                                <Flex w='100vw' justify='center'  >
                                    <QrReader
                                        delay={delay}
                                        style={previewStyle}
                                        onError={handleError}
                                        onScan={handleScan}
                                        facingMode='environment'
                                    />
                                </Flex>
                            )
                        }

                    </Box>
                ) : null}

            </Box>

            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                size='sm'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmar presença!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4} justify='center' align='center'>
                            <Avatar src={student?.uuid ? `https://tonauni.unievangelica.edu.br/api/img-profiles/${student.uuid}.jpg` : ''} size='2xl' />
                            {/* <Text fontSize='1.2rem'>{student?.registration ? student.registration : '1721068'}</Text> */}
                            <Text fontSize='1.2rem'>{student?.name ? student.name : 'Alvaro Lopes Bastos'}</Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={fetchStudent} label={"Confirmar"} />
                        <Button onClick={onClose} label={"Cancelar"} />
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Layout>
    )
}