import { Box, Flex, HStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FaAngleLeft, FaAngleRight, FaCheckCircle, FaRegCircle } from "react-icons/fa"
import Layout from "../../components/Layout"
import { data } from './data'
import './styles.css'



export const History = () => {

    const [activePage, setActivePage] = useState(0);
    const [dataPage, setDataPage] = useState(data);
    const [pagination, setPagination] = useState([<></>]);
    
    const getPage = () => {
        const numberPages = Math.ceil(data.length / 10);
        
        let pagination = new Array;
        
        for (let i = 0; i < numberPages; i++) {
            pagination.push(
                <Box
                    boxSize='2.7rem'
                    border='2px solid #1F2B50'
                    bgColor={activePage == i ? '' : '#1F2B50'}
                    color={activePage == i ? '' : 'gray.50'}
                    borderRadius='1.5rem'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => setActivePage(i)}
                >
                    {i + 1}
                </Box>
            )
        }

        setPagination(pagination);

        

        let first = 0;
        let final = 10;

        let start = first + (activePage * 10)
        let end = final + (activePage * 10 ) 

        let dataPage = data.slice(start, end);

        setDataPage(dataPage);
    }

    useEffect(() => {
        getPage();
    },[activePage])


    return (
        <Layout pageType="Histórico">
            <Box w='100%' p='1rem' h='100%'>
                <Flex flexDir='column' justifyContent='space-between' height='100%'>
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Nome</th>
                                <th>Entrada</th>
                                <th>Saída</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                dataPage.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.date}</td>
                                        <td>{data.name}</td>
                                        {data.checkin ? (
                                            <td><FaCheckCircle style={{ width: '100%', textAlign: 'center' }} color='green' /></td>
                                        ) : (
                                            <td><FaRegCircle style={{ width: '100%', textAlign: 'center' }} /></td>
                                        )}
                                        {data.checkout ? (
                                            <td><FaCheckCircle style={{ width: '100%', textAlign: 'center' }} color='green' /></td>
                                        ) : (
                                            <td><FaRegCircle style={{ width: '100%', textAlign: 'center' }} /></td>
                                        )}
                                    </tr>
                                ))
                            } */}
                            <></>
                        </tbody>
                    </table>

                    <Flex justify='center'>
                        <HStack spacing={1}>
                            <Box
                                boxSize='2.7rem'
                                border='2px solid #1F2B50'
                                borderRadius='1.5rem'
                                color={activePage == 0 ? '' : 'gray.50'}
                                bgColor={activePage == 0 ? '' : '#1F2B50'}
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                pointerEvents={activePage == 0 ? 'none' : 'auto'}
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                <FaAngleLeft />
                            </Box>
                            {pagination}
                            <Box
                               boxSize='2.7rem'
                               border='2px solid #1F2B50'
                               borderRadius='1.5rem'
                               color={activePage == (pagination.length - 1) ? '' : 'gray.50'}
                               bgColor={activePage == (pagination.length - 1) ? '' : '#1F2B50'}
                               display='flex'
                               alignItems='center'
                               justifyContent='center'
                               pointerEvents={activePage == (pagination.length - 1) ? 'none' : 'auto'}
                               onClick={() => setActivePage(activePage + 1)}
                            >
                                <FaAngleRight />
                            </Box>
                        </HStack>

                    </Flex>
                </Flex>
            </Box>
        </Layout>
    )
}