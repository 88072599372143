import { Box, Flex, Icon, IconButton, useMediaQuery } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Button } from "./Button"

interface ICamera {
    sendPhoto: (data: string) => void;
}


export const Camera = ({ sendPhoto }: ICamera) => {

    const [isShorterThan500] = useMediaQuery('(max-width: 500px)');
    const [isRecording, setIsRecording] = useState(true);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [picture, setPicture] = useState('');


    const [delay, setDelay] = useState(false);


    const videoRef = useRef(null);
    //Acess Camera :)

    const getVideo = () => {

        navigator.mediaDevices.getUserMedia({
            video: { width: 200, height: 200, facingMode: "user" }
        })
            .then(stream => {
                let video = videoRef.current;
                //@ts-ignore
                video.srcObject = stream;
                //@ts-ignore
                video.play();
            }).catch(err => {
                console.error(err);
            })
    }

    useEffect(() => {
        getVideo();
    }, [])

    const photoRef = useRef(null);

    const takePhoto = () => {

        const width = 250;
        const height = 250;

        let video = videoRef.current;
        let photo = photoRef.current;


        //@ts-ignore
        photo.width = width;
        //@ts-ignore
        photo.height = height;
        //@ts-ignore
        let ctx = photo.getContext('2d');
        //@ts-ignore
        ctx.drawImage(video, 0, 0, width, height);

        //@ts-ignore
        const imgBase64 = photo.toDataURL("image/png");

        setPicture(imgBase64);
        setIsRecording(false)
        setHasPhoto(true);
    }

    const delayPhoto = () => {
        setDelay(true);
        setTimeout(() => {
            setDelay(false);
            takePhoto();
        }, 300)
    }

    const sendImage = () => {
        let video = videoRef.current;

        //@ts-ignore
        const tracks = video.srcObject.getTracks();
        tracks[0].stop();

        sendPhoto(picture);
    }
    return (

        <Flex w='100vw' h='100vh' justify='center' flexDir='column' align='center'>

                    <Flex flexDir='column' boxSize='15rem' borderRadius='380px' color='#1F2B51' justify='center' align='center' mt={isShorterThan500 ? 'calc(-100vh + 98%)' : '-25rem'} display={isRecording ? 'block' : 'none'}>
                        <video id="cam" ref={videoRef} width='100%' height='100%' style={{ position: 'relative', borderRadius: '8rem' }} ></video>
                    </Flex>
                    <Button bgColor='transparent' position='fixed' isLoading={delay} border='2px solid #FFF' mt={isShorterThan500 ? 'calc(100vh - 60%)' : '35rem'} w='5rem' h='5rem' label={""} onClick={delayPhoto}  display={isRecording ? 'block' : 'none'}></Button>



            <Flex boxSize='15rem' borderRadius='380px' color='#1F2B51' justify='center' align='center' mt='5rem' display={isRecording ? 'none' : 'block'}>
                <canvas ref={photoRef} style={{ borderRadius: '8rem' }} />
            </Flex>
            
                <Flex flexDir='row' justify='space-between' w='100%' align='center' mt='15rem' p={16} display={isRecording ? 'none' : 'flex'}>
                    <Flex w='5rem' h='5rem' justify='center' align='center' bgColor='rgba(255,150,150,0.1)' borderRadius='5px'>
                        <IconButton as={MdClose} w='3.5rem' h='3.5rem' variant='unstyled' fontSize='3rem' color='red.400' aria-label={""}
                            onClick={() => {
                                setIsRecording(true);
                                setHasPhoto(false);
                                getVideo();
                            }}
                        />
                    </Flex>
                    <Flex w='5rem' h='5rem' justify='center' align='center' bgColor='rgba(150,255,150,0.1)' borderRadius='5px'>
                        <IconButton as={FaCheck} w='3rem' h='3rem' variant='unstyled' fontSize='3rem' color='green.400' aria-label=""
                            onClick={() => {
                                sendImage()
                            }}
                        />
                    </Flex>
                </Flex>

        </Flex>

    )
}