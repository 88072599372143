import { Navigate, Route, Routes } from "react-router-dom"

import { Login } from "../pages/Login";
import { NewPassword } from "../pages/NewPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { SignUp } from "../pages/SignUp";
import { PersonCard } from "../pages/PersonCard";
import { Roll } from "../pages/Roll";

import PrivateRoute from "./Route";
import { History } from "../pages/History";
import { useAuth } from "../contexts/authentication";


function IsAuthenticated() {
 
    const {token} = useAuth();
  
    return token ? true : false;
}
const Router: React.FC = () => (
    
    <Routes>

        <Route path="/" element={ !IsAuthenticated() ? <Login /> : <Navigate to='person-card' />}/>
         <Route path="/signup" element={<SignUp />} />
        {/*<Route path="/reset" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} /> */}
        {/* <Route path="/person-card" element={ <PersonCard />} /> */}
        {/* <Route path="/roll" element={ <Roll />} /> */}
        {/* <Route path="/history" element={ < History/>} /> */}

        <Route path="/person-card" element={
            <PrivateRoute redirectTo={'/'} >
                <PersonCard />
            </PrivateRoute>
        } />
        <Route path="/roll" element={
            <PrivateRoute redirectTo={'/'} >
                <Roll />
            </PrivateRoute>
        } />
        <Route path="/history" element={
            <PrivateRoute redirectTo={'/'} >
                <History />
            </PrivateRoute>
        } />

    </Routes>
)

export default Router;
