export const data = [
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Alvaro Lopes',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Josué Sousa',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: true,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
    {
        date: '01/01',
        name: 'Adriana Sodre',
        checkin: false,
        checkout: false
    },
]
