import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'

interface ButtonProps extends ChakraButtonProps {
    label: string;
}

export const Button = ({ label, children,...rest }: ButtonProps) => {

    return (
        <ChakraButton
            fontWeight={500}
            bgColor="#1F2B51"
            color='#FFF'
            borderRadius='3rem'
            w='10rem'
            _hover={{bgColor: 'rgba(31, 44, 81, 0.8)'}}
            _focus={{bgColor: 'rgba(31, 44, 81, 0.8)'}}
            {...rest}
        >
            {label != '' ? (
                label
            ) : null }
            {children && children}
        </ChakraButton>
    )
}