import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
   serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: (e) => {
//     const { waiting: { postMessage = null } = {} as any, update } = e || {};
//     if (postMessage) {
//       postMessage({ type: 'SKIP_WAITING' });
//     }
//     update().then(() => {
//       window.location.reload();
//     });
//   },
// });
