import { Avatar, Box, Button, Flex, IconButton, Progress, Spinner, useMediaQuery, Text, Image } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FaCheck, FaCamera } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import Webcam from 'react-webcam';

import Tutorial from '../assets/tutorial-camera.gif';

interface ICamera {
  sendPhoto: (data: string) => void;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}




export const Camera = ({ sendPhoto, isError, setIsError, isLoading, setIsLoading }: ICamera) => {

  const [photo, setPhoto] = useState('');
  const [isRecording, setIsRecording] = useState(true);
  const [delay, setDelay] = useState(false);
  const [counter, setCounter] = useState(0);
  const [havePermission, setHavePermission] = useState(true);

  const cameraRef = useRef(null);
  const [textLoading, setTextLoading] = useState('Aguarde enquanto enviamos a sua foto!')
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  let interval: NodeJS.Timeout | undefined;

  if (isLoading == true) {
    let text = textLoading != 'Aguarde enquanto enviamos a sua foto!' ? textLoading : 'Isso pode levar um tempo!';
    interval = setInterval(() => {
      console.log('to interval');
      switch (counter) {
        case 1:
          text = 'Isso pode levar um tempo!';
          break;
        case 2:
          text = 'Isso pode levar um tempo!';
          break;
        case 3:
          text = 'Já estamos finalizando...';
          break;
        case 4:
          text = 'Já estamos finalizando...';
          break;
      }
      setTextLoading(text);
      setCounter(counter + 1);
    }, 6000)
  }

  setTimeout(() => {
    clearInterval(interval)
  }, 24000)


  const verifyPermission = async () => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(res => {
      setHavePermission(true);
      console.log(res)
    }).catch(err => {
      console.log(err);
      setHavePermission(false);
    })
  }

  const startCamera = async () => {
    navigator.mediaDevices.getUserMedia({ 
      audio: false,
      video: {
        facingMode: 'user'
      },
     }).then(res => {
      let cam = cameraRef.current;

      cam.srcObject = res;
      cam.play();
      setHavePermission(true);
      console.log(res)
    }).catch(err => {
      console.log(err);
      setHavePermission(false);
    })
  }


  const capturePhoto = () => {
    let video = cameraRef.current;

    var canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 640;
    var ctx = canvas.getContext('2d');
    ctx.translate(canvas.width, 0)
    ctx.scale(-1,1)
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL();
    console.log(dataURL);

    setPhoto(dataURL);
    setIsRecording(false);
    setIsError(false);
  }

  useEffect(() => {
    verifyPermission();

    if(photo == '') {
      startCamera();
    }
  }, [isRecording])

  return (
    havePermission ? (
      isRecording || isError ? (
        <>
          <div style={{
            display: 'flex',
            widows: '100vw',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {/* <Webcam
              audio={false}
              screenshotFormat="image/png"
              videoConstraints={videoConstraints}
            >
              {({ getScreenshot }) => {

                return (
                  <>
                    {/* <Flex pos='absolute' justify='center' w='100%' h='100%'>
                    <Box w='90%' mt='10rem' height='55%' border='5px dashed #F0F0F0' borderRadius='20rem'></Box>
                  </Flex>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'absolute',
                      bottom: '10px'
                    }}
                    >
                      <Button
                      style={{
                        width: '5rem',
                        height: '5rem',
                        backgroundColor: '#FFF',
                        border: '2px solid #FFF',
                        borderRadius: '3rem'
                      }}
                        isLoading={delay}
                        onClick={() => {
                          const imageSrc = getScreenshot()
                          setPhoto(imageSrc);
                          setIsRecording(false);
                          setIsError(false);
                        }}
                      >
                        <IconButton as={FaCamera} w='3.5rem' h='3.5rem' color='#A0A0A0' variant='unstyled' fontSize='3rem' aria-label={''} />
                      </Button>
                    </div>
                  </>
                )
              }
              }
            </Webcam> */}
            <video id="video" width="640" height="480" ref={cameraRef} autoPlay playsInline muted style={{
              WebkitTransform: 'scaleX(-1)',
              transform: 'scaleX(-1)'
            }}></video>
            <button id="capture"
              style={{
                backgroundColor: '#F0F0F0',
                width: '5rem',
                height: '5rem',
                marginTop: '1rem',
                borderRadius: '5rem'
              }}

              onClick={capturePhoto}
            >                        <IconButton as={FaCamera} w='2.4rem' h='2.4rem' color='#A0A0A0' variant='unstyled' fontSize='3rem' aria-label={''} />
            </button>
          </div>
        </>
      ) : (
        <>
          <Flex flexDir='column' align='center'>
            <Avatar boxSize='11rem' mt='1.5rem' src={photo} />
          </Flex>
          {
            isLoading ? (
              // <Flex flexDir='row' justify='center' w='100%' align='center' mt='15rem' p={16} display={isRecording ? 'none' : 'flex'}>
              //   <Spinner size='xl' color='#FAFAFA'/>

              // </Flex>
              <Flex flexDir='column' justify='center' align='center' w='100%' mt='5rem'>
                <Text fontSize='1.3rem' color='#FAFAFA' >{textLoading}</Text>
                <Progress w='80%' h='12px' isIndeterminate />
              </Flex>

            ) : (
              <Flex flexDir='row' justify='space-between' w='100%' align='center' mt='8rem' p={16} display={isRecording ? 'none' : 'flex'}>
                <Flex w='5rem' h='5rem' justify='center' align='center' bgColor='rgba(255,150,150,0.1)' borderRadius='5px'>
                  <IconButton as={MdClose} w='3.5rem' h='3.5rem' variant='unstyled' fontSize='3rem' color='red.400' aria-label={""}
                    onClick={() => {
                      setIsRecording(true);
                      setPhoto('');
                    }}
                  />
                </Flex>
                <Flex w='5rem' h='5rem' justify='center' align='center' bgColor='rgba(150,255,150,0.1)' borderRadius='5px'>
                  <IconButton as={FaCheck} w='3rem' h='3rem' variant='unstyled' fontSize='3rem' color='green.400' aria-label=""
                    onClick={() => {
                      sendPhoto(photo);
                      setIsLoading(true);
                    }}
                  />
                </Flex>
              </Flex>
            )
          }


        </>
      )
    ) : (
      <Flex w='100%' justify='center' flexDir='column' align='center' >
        <Box bgColor='#FAFAFA' w='90%' borderRadius='5px' mt='1rem' >
          <Text color='#000000' textAlign='center' mt='2rem'><b>OOPSS... No momento não temos permissão para acessar sua câmera</b></Text>
          <Text color='#000000' textAlign='center' mt='1rem'>Siga o tutorial para permitir o acesso a câmera: </Text>
        </Box>
        <Image src={Tutorial} w='70%' alt='Tutorial de permissão da câmera' mt='1rem' />
      </Flex>
    )


  );
}