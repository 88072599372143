import { Box, Button as ChakraButton, Flex, Icon, Stack, Text, Link, Image, Tabs, TabList, Tab, TabPanels, TabPanel, useMediaQuery } from '@chakra-ui/react';
import { FaArrowRight, FaCheck, FaExclamationTriangle, FaIdCard, FaLock, FaSignInAlt } from 'react-icons/fa';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form';

import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/authentication';

import LogoUni from '../../assets/logo.svg';
import LogoCouto from '../../assets/logoCouto.svg';
import LogoColoridaUni from '../../assets/logo_colorida.svg';
import LogoColoridaCouto from '../../assets/logo_colorida_couto.svg';
import Onboard01 from '../../assets/onboard01.svg';
import Onboard02 from '../../assets/onboard02.svg';
import Onboard03 from '../../assets/onboard03.svg';

interface SignInFormData {
    cpf: string;
    password: string;
}

export const Login = () => {


    const { actualApp, signIn } = useAuth();
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [finishTutorial, setFinishTutorial] = useState(false);
    const [isCouto, setIsCouto] = useState(false);


    useEffect(() => {
        if (actualApp.includes('tonocouto')) {
            setIsCouto(true)
        }
    }, [])

    const Logo = isCouto ? LogoCouto : LogoUni;
    const LogoColorida = isCouto ? LogoColoridaCouto : LogoColoridaUni;

    const topCard01 = isCouto ? 'Seu cartão virtual para acesso ao Colégio Couto Magalhães' : 'Bem-Vindo a sua carteirinha virtual!';
    const bottomCard01 = isCouto ? 'Seguro, Prático, Intransferível' : 'Com ela você terá acesso a Universidade.';

    const textCadastrar = isCouto ? 'Cadastre-se aqui!' : 'Visitante? cadastre-se aqui!'

    const [isShorterThan750] = useMediaQuery('(max-height: 750px)');
    const [isShorterThan645] = useMediaQuery('(max-height: 645px)');

    const signInFormSchema = yup.object().shape({
        cpf: yup.string().required('CPF obrigatório.'),
        password: yup.string().required('Senha obrigatória.')
    })

    const { register, handleSubmit, formState: { errors } } = useForm<SignInFormData>({
        resolver: yupResolver(signInFormSchema)
    });

    const onSubmit: SubmitHandler<SignInFormData> = async (data) => {

        try {
            await signIn({ cpf: data.cpf, password: data.password });
            navigate('/person-card');
            setIsError(false);
        } catch (error) {
            console.log('Erro na autenticação:    ', error);
            setIsError(true);
        }
    };

    return (

        finishTutorial ? (
            <Flex w='100vw' h='100vh' align='center' justify='center' flexDir='column' >
                <Box boxSize={isCouto ? '15rem' : '11rem'} >
                    <Image src={Logo} alt='Logo APP' ml={isCouto ? '-1rem' : ''} />
                </Box>
                <Flex bgColor='#FCFCFC' w='85%' minHeight='23rem' maxW={360} p={4} flexDir='column' borderRadius='1.5rem' mt={isCouto ? '-2rem' : ''}>
                    <Text fontFamily='Overpass' fontSize='1.3rem' >Olá!</Text>
                    <Text fontFamily='Overpass' fontSize='1.3rem'>Acesse sua carteirinha virtual.</Text>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4} color='#1F2B51' justify='center' align='center' mt='1rem' >
                            <Input id='cpf' mask='cpf' inputMode="numeric" type='text' placeholder="CPF*" icon={<Icon as={FaIdCard} />} maxLength={14} {...register('cpf')} error={errors.cpf} />
                            <Input id='password' type='password' placeholder='Senha*' icon={<Icon as={FaLock} />} {...register('password')} error={errors.password} />
                            {
                                isError ? (
                                    <Flex w='100%' bgColor='#F8D7DA' p={2} align='center'>
                                        <Icon as={FaExclamationTriangle} color='#842029' fontSize='4xl' mr='1rem' />
                                        <Box>
                                            <Text color='#1F2B50'>Usuário ou senha inválidos.</Text>
                                            <Text color='#1F2B50'>Tente novamente.</Text>
                                        </Box>
                                    </Flex>
                                ) : null}
                            <Text fontFamily='Overpass' fontSize='0.79rem' mt='1rem' display={isCouto ? 'none' : 'block'}>Aluno e Professor, acesse com a senha do Lyceum.</Text>
                            <a href='https://mobile.unievangelica.edu.br/aluno/#/resetsenha'>Esqueci minha senha.</a>
                            <Button type='submit' label='Entrar' />
                        </Stack>
                    </form>
                    <Flex flexDir='column' align='center' justify='center' marginTop='3rem'>
                        <Text fontFamily='Overpass' fontSize='1.3rem' color='black'>{textCadastrar}</Text>
                        <Link as={ReachLink} to='/signup'>
                            <ChakraButton color='black' leftIcon={<Icon as={FaSignInAlt} />} variant='unstyled'>Cadastrar</ChakraButton>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        ) : (
            <Flex w='100%' flexDir='row' justifyContent='center'  >
                <Flex w='90%' h='calc(100vh - 10vh)' flexDir='column' align='center' bgColor='gray.50' borderRadius=' 0  0 1rem 1rem' color='#5D539F'>
                    <Tabs index={tabIndex} variant='' >
                        {isCouto ? (
                            <>
                                <TabPanels >
                                    <TabPanel display='flex' flexDir='column' justifyContent='center' alignItems='center' textAlign='center'>
                                        <Image src={LogoColorida} w={isShorterThan750 ? '7rem' : '8rem'} alt='Logo Tô na Uni' mt='3rem' />
                                        <Text fontWeight={500} mt='3rem' fontSize='19px' >{topCard01}</Text>
                                        <Image src={Onboard01} w={isShorterThan750 ? '13rem' : '25rem'} alt='Cartão de identidade e celular' />
                                        <Text fontWeight={500} fontSize='18px'>{bottomCard01}</Text>
                                    </TabPanel>
                                    <TabPanel display='flex' flexDir='column' justifyContent='center' alignItems='center' textAlign='center'>
                                        <Image src={LogoColorida} w={isShorterThan750 ? '7rem' : '8rem'} alt='Logo Tô na Uni' mt='3rem' />
                                        <Text fontWeight={500} mt='3rem' fontSize='19px'>Aproxime a tela com o Qrcode até o leitor da catraca</Text>
                                        <Image src={Onboard03} w={isShorterThan750 ? '13rem' : '17rem'} alt='Codigo qr e robô' />
                                        <Text fontWeight={500} fontSize='18px'>Pronto! Você já tem seu acesso liberado.</Text>
                                    </TabPanel>
                                </TabPanels>
                                <Flex w='100%' justify='center' align='center' position='absolute' bottom={isShorterThan645 ? '2.4rem' : '4rem'} >
                                    <TabList borderBottom='none'>
                                        <Tab w='3.3rem' mr='1rem' borderBottom='4px solid' borderRadius='3px' color='#5d539f3d' _selected={{ color: '#5D539F' }} onClick={() => setTabIndex(0)}></Tab>
                                        <Tab w='3.3rem' mr='1rem' borderBottom='4px solid' borderRadius='3px' color='#5d539f3d' _selected={{ color: '#5D539F' }} onClick={() => setTabIndex(1)}></Tab>
                                    </TabList>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <TabPanels >
                                    <TabPanel display='flex' flexDir='column' justifyContent='center' alignItems='center' textAlign='center'>
                                        <Image src={LogoColorida} w={isShorterThan750 ? '7rem' : '8rem'} alt='Logo Tô na Uni' mt='3rem' />
                                        <Text fontWeight={500} mt='3rem' fontSize='19px' >{topCard01}</Text>
                                        <Image src={Onboard01} w={isShorterThan750 ? '13rem' : '25rem'} alt='Cartão de identidade e celular' />
                                        <Text fontWeight={500} fontSize='18px'>{bottomCard01}</Text>
                                    </TabPanel>
                                    <TabPanel display='flex' flexDir='column' justifyContent='center' alignItems='center' textAlign='center' >
                                        <Image src={LogoColorida} w={isShorterThan750 ? '7rem' : '8rem'} alt='Logo Tô na Uni' mt='3rem' />
                                        <Text fontWeight={500} mt='3rem' fontSize='19px'>Basta fazer o login utilizando CPF e a senha do seu lyceum.</Text>
                                        <Image src={Onboard02} w={isShorterThan750 ? '13rem' : '18rem'} alt='entradas de cpf e um código qr' />
                                        <Text fontWeight={500} fontSize='18px'>Assim terá acesso ao Qrcode</Text>
                                    </TabPanel>
                                    <TabPanel display='flex' flexDir='column' justifyContent='center' alignItems='center' textAlign='center'>
                                        <Image src={LogoColorida} w={isShorterThan750 ? '7rem' : '8rem'} alt='Logo Tô na Uni' mt='3rem' />
                                        <Text fontWeight={500} mt='3rem' fontSize='19px'>Aproxime a tela com o Qrcode até o leitor da catraca</Text>
                                        <Image src={Onboard03} w={isShorterThan750 ? '13rem' : '17rem'} alt='Codigo qr e robô' />
                                        <Text fontWeight={500} fontSize='18px'>Pronto! Você já tem seu acesso liberado.</Text>
                                    </TabPanel>
                                </TabPanels>
                                <Flex w='100%' justify='center' align='center' position='absolute' bottom={isShorterThan645 ? '2.4rem' : '4rem'} >
                                    <TabList borderBottom='none'>
                                        <Tab w='3.3rem' mr='1rem' borderBottom='4px solid' borderRadius='3px' color='#5d539f3d' _selected={{ color: '#5D539F' }} onClick={() => setTabIndex(0)}></Tab>
                                        <Tab w='3.3rem' mr='1rem' borderBottom='4px solid' borderRadius='3px' color='#5d539f3d' _selected={{ color: '#5D539F' }} onClick={() => setTabIndex(1)}></Tab>
                                        <Tab w='3.3rem' mr='1rem' borderBottom='4px solid' borderRadius='3px' color='#5d539f3d' _selected={{ color: '#5D539F' }} onClick={() => setTabIndex(2)}></Tab>
                                    </TabList>
                                </Flex>
                            </>
                        )}

                    </Tabs>
                    <Flex w='5rem' h='5rem' bgColor='gray.50' mt='2rem' borderRadius='3rem' align='center' p={'7px'} position='absolute' bottom='-3rem' >
                        {isCouto ? (
                            tabIndex != 1 ? (
                                <Flex w='100%' h='100%' bgColor='white' borderRadius='3rem' border='3px solid #5D539F' align='center' justify='center' onClick={() => setTabIndex(tabIndex + 1)}>
                                    <FaArrowRight size='1.6rem' />
                                </Flex>
                            ) : (
                                <Flex w='100%' h='100%' bgColor='white' borderRadius='3rem' border='3px solid #5D539F' align='center' justify='center' onClick={() => setFinishTutorial(true)}>
                                    <FaCheck size='1.6rem' />
                                </Flex>
                            )
                        ) : (
                            tabIndex != 2 ? (
                                <Flex w='100%' h='100%' bgColor='white' borderRadius='3rem' border='3px solid #5D539F' align='center' justify='center' onClick={() => setTabIndex(tabIndex + 1)}>
                                    <FaArrowRight size='1.6rem' />
                                </Flex>
                            ) : (
                                <Flex w='100%' h='100%' bgColor='white' borderRadius='3rem' border='3px solid #5D539F' align='center' justify='center' onClick={() => setFinishTutorial(true)}>
                                    <FaCheck size='1.6rem' />
                                </Flex>
                            )
                        )}



                    </Flex>
                </Flex>
            </Flex>
        )
    )
}
