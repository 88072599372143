import { useRef, useState } from "react";

import { Box, Flex, Icon, Stack, Text, Image, useMediaQuery, IconButton, useToast, Button as ChakraButton, Spinner } from "@chakra-ui/react";
import { FaArrowLeft, FaBook, FaCamera, FaCheck, FaIdCard, FaLock, FaMobileAlt, FaUserTie } from "react-icons/fa";
import { Input, InputPassword } from "../../components/Input";
import { Button } from "../../components/Button";
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';

import IconCamera from '../../assets/icon-camera.svg';
import { SubmitHandler, useForm } from "react-hook-form";
import { person } from './data'
import api from "../../services/api";

import { Camera } from '../../components/Camera-old'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authentication";

interface SignUpFormData {
    cpf: string;
    name: string;
    phone: string;
    password: string;
    password_confirmation: string;
}

interface SignUpStudentFormData {
    cpf: string;
    name: string;
    phone: string;
    course: string;
}

interface FormValidityData {
    cpf_validity: string;
}

export const SignUp = () => {

    const [isShorterThan760] = useMediaQuery('(max-height: 760px)');
    const gradient = 'linear-gradient(180deg, rgba(255,255,255,1) 12%, rgba(238,240,245,1) 67%, rgba(220,225,239,1) 100%)';
    const [isVisible, setIsVisible] = useState(true);
    const [isGuest, setIsGuest] = useState(true);
    const [cpfGuest, setCpfGuest] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [hasPhoto, setHasPhoto] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState('');
    const [imageRequired, setImageRequired] = useState(false);
    const {actualApp ,signIn} = useAuth();
    const navigate = useNavigate();
    const message = useToast();

    const SignUpFormSchema = yup.object().shape({
        cpf: yup.string().required('CPF obrigatório').min(14, 'Digite um CPF válido'),
        name: yup.string().matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Digite seu nome completo').required('Nome obigatório'),
        phone: yup.string().required('Telefone obrigatório').min(14, 'Digite um telefone válido'),
        password: yup.string().required('Senha é obrigatório'),
        password_confirmation: yup.string().oneOf([
            null, yup.ref('password')
        ], 'As senha precisam ser iguais'),

    });

    const SignUpFormStudentSchema = yup.object().shape({
        cpf: yup.string().required('CPF obrigatório').min(14, 'Digite um CPF válido'),
        name: yup.string().required('Nome obigatório'),
        phone: yup.string().required('Telefone obrigatório').min(14, 'Digite um telefone válido'),
        course: yup.string().required('Curso é obrigatório'),
    });

    const ValidityFormSchema = yup.object().shape({
        cpf_validity: yup.string().required('CPF é obrigatório').min(14, 'Digite um CPF válido'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm<SignUpFormData>({
        resolver: yupResolver(SignUpFormSchema)
    });

    const { register: registerStudent, handleSubmit: handleSubmitStudent, formState: { errors: errorsStudent } } = useForm<SignUpStudentFormData>({
        resolver: yupResolver(SignUpFormStudentSchema)
    });

    const { register: registerValidity, handleSubmit: handleSubmitValidity, formState: { errors: errorsValidity } } = useForm<FormValidityData>({
        resolver: yupResolver(ValidityFormSchema)
    });

    const fetchData: SubmitHandler<FormValidityData> = (data) => {
        console.log(data);

        //Remove Mask
        const cpf = data.cpf_validity.replace(/\D/g, '');

        setIsVisible(true);
        setCpfGuest(data.cpf_validity)
        setIsGuest(true);
    }

    const boxPhotoRef = useRef(null);

    const onSubmit: SubmitHandler<SignUpFormData> = async (data) => {

        const cpf = data.cpf.replace(/\D/g, '');
        const phone = data.phone.replace(/\D/g, '');

        setIsLoading(true);

        api.post('/app/register', {
            cpf: cpf,
            name: data.name,
            phone: phone,
            password: data.password,
            origin: actualApp.includes('tonocouto') ? 'couto' : 'unievangelica'
        })
            .then(async (res) => {
                message({
                    title: 'Sucesso!',
                    description: 'Usuário cadastrado com sucesso!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
                await signIn({ cpf: data.cpf, password: data.password });
                navigate('/person-card');
                setIsLoading(false);
            })
            .catch((error) => {
                error.response.data.cpf[0] === "The cpf has already been taken."
                    ?
                    message({
                        title: 'Atenção!',
                        description: 'CPF já cadastrado!',
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                        position: 'top'
                    })
                    :
                    message({
                        title: 'Erro ao enviar dados!',
                        description: 'Tente novamente mais tarde!',
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                        position: 'top'
                    })
                setIsLoading(false);
            }
            )

    }

    const onSubmitStudent: SubmitHandler<SignUpStudentFormData> = (data) => {
        console.log(data);
    }

    const sendPhoto = (data: string) => {
        setImage(data);
        setIsRecording(false);
        setHasPhoto(true);
    }


    return (

        <Flex flexDir='column' align='center' h='100vh'>
            <IconButton icon={<FaArrowLeft size='1.8rem' />} aria-label={""} onClick={() => navigate('/')} bg='none' _hover={{bgColor: 'none'}} _focus={{bgColor: 'none'}} color='#FAFAFA' position='absolute' left={2} top={2} />
            {isRecording && !hasPhoto ? (
                <Camera sendPhoto={sendPhoto} />
            ) : !isRecording && hasPhoto ? (
                // <Flex flexDir='column' w='11rem' h='11rem' borderRadius='8rem' color='#1F2B51' justify='center' align='center' mt='4rem' display={hasPhoto ? 'block' : 'none'} >
                //     <Image src={image} borderRadius='8rem' />
                // </Flex>
                <Flex flexDir='column' w='11rem' h='11rem' bgColor='#FCFCFC' border={imageRequired ? '1px solid #E53E3E' : ''} borderRadius='9rem' color='#1F2B51' justify='center' align='center' mt='4rem' ref={boxPhotoRef}>
                    <Icon as={FaUserTie} fontSize='7xl' />
                    {/* <Image src={IconCamera} boxSize='1.7rem' mt='2rem' color='black' onClick={() => { setIsRecording(true) }} display={isGuest ? 'block' : 'none'} /> */}
                </Flex>
            ) : (
                <>
                    <Flex flexDir='column' w='11rem' h='11rem' bgColor='#FCFCFC' border={imageRequired ? '1px solid #E53E3E' : ''} borderRadius='9rem' color='#1F2B51' justify='center' align='center' mt='4rem' ref={boxPhotoRef}>
                        <Icon as={FaUserTie} fontSize='7xl' />
                        <Image src={IconCamera} boxSize='1.7rem' mt='2rem' color='black' onClick={() => { setIsRecording(true) }} display={isGuest ? 'block' : 'none'} />
                    </Flex>
                    <Text color='#E53E3E' display={imageRequired ? 'block' : 'none'}>É obrigatório o envio de uma foto!</Text>
                </>
            )
            }

            <Flex flexDir='column' justify='center' align='center' display={isRecording ? 'none' : 'block'} bgGradient={gradient} mt='1rem' w='92%' h='100%' borderTopRadius='1.7rem' maxW='700px'>
                <Flex flexDir='row' justify='center' align='center' mb='2rem' bgColor='#237FC3' w='100%' borderTopRadius='1.7rem' minH='3rem' color='#fff'>
                    <Text fontSize='lg'>{isVisible && isGuest ? 'Visitante' : isVisible && !isGuest ? person.type : ''}</Text>
                </Flex>
                <Flex justify='center'>
                    <Box w='95%' >
                        {!isVisible ? (
                            <form action="" onSubmit={handleSubmitValidity(fetchData)}>
                                <Input id="cpf_validity" mask="cpf" inputMode="numeric" placeholder={'CPF*'} icon={<Icon as={FaIdCard} />} error={errorsValidity.cpf_validity} {...registerValidity('cpf_validity')} />
                                <Flex justify='center'>
                                    <Button type='submit' label='Validar' mt='2rem' mb='1rem' />
                                </Flex>
                            </form>
                        ) : null}

                        {isVisible && isGuest ? (
                            <form action="" onSubmit={handleSubmit(onSubmit)}>
                                <Stack>
                                    <Input id="cpf" mask="cpf" inputMode="numeric" placeholder={'CPF*'} defaultValue={cpfGuest} icon={<Icon as={FaIdCard} />} error={errors.cpf} {...register('cpf')} />
                                    <Input id="name" placeholder={'Nome*'} icon={<Icon as={FaUserTie} />} error={errors.name} {...register('name')} />
                                    <Input id="phone" type='tel' mask="phone" placeholder={'Telefone*'} icon={<Icon as={FaMobileAlt} />} error={errors.phone} {...register('phone')} />
                                    <InputPassword id="password" placeholder={'Senha*'} icon={<Icon as={FaLock} />} error={errors.password} {...register('password')} />
                                    <InputPassword id="password_confirmation" placeholder={'Confirme sua senha*'} icon={<Icon as={FaLock} />} error={errors.password_confirmation} {...register('password_confirmation')} />
                                </Stack>
                                <Flex justify='center'>
                                    {isLoading ? (
                                        <Button type='submit' label={''} children={<Spinner size='md' color='#FAFAFA' />} mt='5rem' mb='1rem' disabled />
                                    ) : (
                                        <Button type='submit' label='Cadastrar' mt='5rem' mb='1rem' />
                                    )}
                                </Flex>
                            </form>
                        ) : null}

                        {isVisible && !isGuest ? (
                            <form action="" onSubmit={handleSubmitStudent(onSubmitStudent)}>
                                <Stack>
                                    <Input id="cpf" inputMode="numeric" placeholder={'CPF*'} icon={<Icon as={FaIdCard} />} defaultValue={person.cpf} isReadOnly error={errorsStudent.cpf} {...registerStudent('cpf')} />
                                    <Input id="name" placeholder={'Nome*'} icon={<Icon as={FaUserTie} />} defaultValue={person.name} isReadOnly error={errorsStudent.name} {...registerStudent('name')} />
                                    <Input id="phone" type='tel' placeholder={'Telefone*'} icon={<Icon as={FaMobileAlt} />} defaultValue={person.phone} isReadOnly error={errorsStudent.phone} {...registerStudent('phone')} />
                                    <Input id="course" placeholder={'Curso*'} icon={<Icon as={FaBook} />} defaultValue={person.course} isReadOnly error={errorsStudent.course} {...registerStudent('course')} />
                                </Stack>
                                <Flex justify='center'>
                                    {isLoading ? (
                                        <ChakraButton><Spinner size='md' color='#FAFAFA' /></ChakraButton>
                                    ) : (
                                        <Button type='submit' label='Cadastrar' mt='5rem' mb='1rem' />
                                    )}
                                </Flex>
                            </form>
                        ) : null}
                    </Box>
                </Flex>

            </Flex>
        </Flex>
    )
}