import { extendTheme } from '@chakra-ui/react';
import { config } from './config';

import fundoUni from '../assets/backgroundUni.svg';
import fundoCouto from '../assets/backgroundCouto.svg';
import { url } from 'inspector';
import { useAuth } from '../contexts/authentication';


if (window.location.href == 'https://tonocouto.colegiocoutomagalhaes.com.br/' || window.location.href == 'http://tonocouto.colegiocoutomagalhaes.com.br/') {
    window.location.assign(`https://tonocouto.aee.edu.br`)
} 

if (window.location.href.split(':')[0] == 'http') {
    console.log(window.location.href)
    window.location.assign(`https://${window.location.href.split('http://')[1]}`)
}

const actualApp = window.location.href.split('https://')[1];
let body;

let bodyUni = {
    backgroundImage: `url(${fundoUni})`,
    background: 'linear-gradient(317deg, rgba(23,167,227,1) 48%, rgba(25,198,226,1) 74%);',
    backgroundAttachment: 'fixed',
    backgroundPosition: "center",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#1F2B51'
};

let bodyCouto = {
    backgroundImage: `url(${fundoCouto})`,
    background: 'linear-gradient(317deg, rgba(23,167,227,1) 48%, rgba(25,198,226,1) 74%);',
    backgroundAttachment: 'fixed',
    backgroundPosition: "center",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#1F2B51'
}

actualApp.includes('tonocouto') ? body = bodyCouto : body = bodyUni;

export const theme = extendTheme({
    fonts: {
        heading: 'Roboto',
        body: 'Roboto'
    },
    styles: {
        global: {
            body
        }
    }

})