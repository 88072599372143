import { Box, Flex, Icon, IconButton, Stack, Text, Image, useBreakpointValue, useMediaQuery, Avatar } from "@chakra-ui/react";
import { FaAddressBook, FaBook, FaCalendarAlt, FaEnvelope, FaUserTie } from "react-icons/fa";
import { QRCodeSVG, QRCodeCanvas } from 'qrcode.react'

import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/authentication";
import { Button } from "../../components/Button";


export const PersonCard = () => {

    const { user, actualApp } = useAuth();
    const navigate = useNavigate();

    const person = {
        name: 'Alvaro Lopes Bastos',
        course: 'Engenharia de computação',
        type: 'UniEVANGÉLICA',
        cpf: '44430320888',
        validity: '2022-12-31T19:58:47.1234567',
        photo: 'https://bit.ly/dan-abramov'
    }

    let cpfFormatted = user.cpf.replace(/\D/g, "");
    cpfFormatted = cpfFormatted.replace(/(\d{3})(\d)/, '$1.$2');
    cpfFormatted = cpfFormatted.replace(/(\d{3})(\d)/, '$1.$2');
    cpfFormatted = cpfFormatted.replace(/(\d{3})(\d)/, '$1-$2');

    person.cpf = cpfFormatted;

    let tagFormatted = ("0000000000" + user.tags[0].id).slice(-10);
    
    
    const local = actualApp.includes('tonocouto') ? 'Colégio Couto Magalhães' : 'UniEVANGÉLICA';


    return (
        <Layout pageType={local}>
            <Box w='100%' p='0 2rem 1rem 2rem'>
                <Stack spacing={8}>
                    <Text><Icon as={FaUserTie} /> {user.name}</Text>
                    {/* <Text><Icon as={FaBook} /> {person.course}</Text> */}
                    <Flex flexDir='row' justify='space-between'>
                        <Text><Icon as={FaEnvelope} /> {person.cpf}</Text>
                        {/* <Text><Icon as={FaCalendarAlt} /> {new Intl.DateTimeFormat('pt-BR').format(new Date(person.validity))} </Text> */}
                    </Flex>
                </Stack>
                <Flex justify='center'>
                    <Box borderRadius='1rem' mt='4rem' maxW='290px'>
                        <QRCodeCanvas
                            value={`${tagFormatted}`}
                            size={256}
                            style={{ maxWidth: '100%', borderRadius: '0.8rem', border: '7px solid #fafafa' }}
                            bgColor='#FFF'
                        />
                    </Box>
                </Flex>

                {
                    user.checkins.length > 0 ? (
                        <Flex justify='center' mt='3rem'>
                            <Button label="Frequência" onClick={() => navigate('/roll')} />
                        </Flex>
                    ) : null
                }

            </Box>
        </Layout>
    )
}