import React, { createContext, useCallback, useState, useContext } from 'react';
import qs from 'qs';

import api from '../services/api';
import jwt_decode from 'jwt-decode'

interface IUser {
    uuid: string;
    name: string;
    cpf: string;
    allow_acess: string;
    tags: {
        id: number;
    }[];
    checkins: {
        id: number;
        name: string;
    }[];
}

interface AuthContextData {
    user: IUser;
    token: string;
    actualApp: string;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
}

export interface SignInCredentials {
    cpf: string;
    password: string;
}

interface AuthState {
    user: IUser;
    token: string;
}

interface UserData {
    data: string;
}

export interface AuthProviderProps {
    children: React.ReactNode;
}

export type JWTDecodedProps = {
    aud: string;
    iss: string;
    iat: number;
    sub: string;
  };

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider= ({children}: AuthProviderProps) => {

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem("@ToNaUni:token");
        const user = localStorage.getItem("@ToNaUni:user");
        
        if(token && user) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            return {token, user: JSON.parse(user)};
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ cpf, password }: SignInCredentials) => {
        const username = cpf.replace(/\D/g, ""); // \D pegar tudo que não é número, g continuar a ler a string;

        const response = await api.post('/app/login', {
            username,
            password,
            origin: window.location.href.split('https://')[1].includes('tonocouto') ? 'couto' : 'unievangelica'
        })
        
        const  token   = response.data;

        localStorage.setItem('@ToNaUni:token', token)

        const decode:JWTDecodedProps = jwt_decode(token);

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        const uuid = decode.sub;
        
        const user_data = await api.get(`/app/card?uuid=${uuid}`);

        localStorage.setItem('@ToNaUni:user', JSON.stringify(user_data.data))

        const user = user_data.data;

        setData({ token, user });
    }, []);

    const signOut = useCallback(() => {

        localStorage.removeItem('@ToNaUni:token');
        localStorage.removeItem('@ToNaUni:user');
        api.defaults.headers.common['Authorization'] = '';

        setData({} as AuthState);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                token: data.token,
                actualApp: window.location.href.split('https://')[1],
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}


export { AuthProvider, useAuth };
