import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import Router from './routes';
import { theme } from './styles/theme';
import AppProvider from './contexts';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
      <BrowserRouter basename={`${process.env.REACT_APP_BASE_URL}`}>
        <Router />
      </BrowserRouter>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
